.App {
  min-height: 100vh;
  background: #E2E8F0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 350px;
}

.generator {
  background: #2c5282;
  border-radius: 3px;
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.2);
  padding: 20px;
}

.generator__header {
  font-weight: 700;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.generator__password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #E2E8F0;
  padding: 13px 10px;
  color: #2c5282;
  height: 46px;
  margin-bottom: 15px;
}

.copy__btn {
  background: transparent;
  font-size: 20px;
  color: #2c5282;
  border: none;
  cursor: pointer
}

.password-strength__input {
  background: #E2E8F0;
  padding: 5px;
  font-size: 20px;
  color: #2c5282;
  border: none;
  text-align: center;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.generator__btn {
  background: #3182ce;
  border: none;
  display: block;
  width: 100%;
  padding: 15px;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;

  transition: filter 0.2s;
}

.generator__btn:hover {
  filter: brightness(0.9);
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-bottom: 15px;
}